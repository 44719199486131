<template>
  <div>
    <Header title="工单记录" color="#666" />
    <van-steps
      direction="vertical"
      center
      active-color="#1989fa"
      inactive-color="#999"
      :active="list.length - 1"
    >
      <van-step v-for="(item, index) in list" :key="index">
        <div class="flex-row" v-if="item.disposeResult">
          <div class="flex-grow-1">
            {{ item.disposeResult }}
          </div>
          <div class="flex-grow-0">
            {{ parseTime(item.createTime) }}
          </div>
        </div>
        <div v-else class="flex-row">处理中</div>

        <div class="flex-col fs13">
          <div class="flex-grow-0 flex-row mgt10 color999">
            <div class="item flex-grow-1">设备名称：{{ item.mainName }}</div>
            <div class="item flex-grow-0 flex-row">
              设备编号：{{ item.mainNum }}
            </div>
          </div>
          <div class="flex-grow-0 flex-row mgt10 color999">
            设备编号：{{ item.mainType }}
          </div>
        </div>
      </van-step>
    </van-steps>
  </div>
</template>
<script>
import { getGongdanRecordList } from "@/api/gongdan";
import { Cell, CellGroup, Toast, Tag, Step, Steps } from "vant";
import Header from "@/components/header";
export default {
  name: "GongdanDetail",
  components: {
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Toast.name]: Toast,
    [Tag.name]: Tag,
    [Step.name]: Step,
    [Steps.name]: Steps,
    Header
  },
  data() {
    return {
      loading: false,
      list: []
    };
  },
  computed: {},
  created() {
    this.parseTime = this.utils.parseTime;
    const id = this.$route.query.id;
    if (isNaN(id)) {
      return Toast.fail("参数错误！");
    }
    this.id = id;
    this.getGongdanRecordList();
  },
  mounted() {},
  updated() {},
  methods: {
    getGongdanRecordList() {
      const id = this.id;
      getGongdanRecordList({ id: id })
        .then(res => {
          this.list = res.data;
          Toast.clear();
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.form /deep/ {
  padding: 20px 0px;
  .btn {
    width: 175px;
    margin: 20px 100px;
  }
  .van-field__label {
    flex: none;
    width: 85px;
  }
}
.van-step--finish {
  color: #999;
}
</style>